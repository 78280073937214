import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PriceComponent } from "../../utility/utility";

const useStyles = makeStyles((theme) => ({
  container: {
    display:"flex",
    justifyContent:"flex-end",
    height:"40px",
    width:"160px",
    marginLeft:'auto',
    marginRight:'auto'
  },
  percentStep:{
    border:"1px solid white",
    width:"10px"
  },
  percentText:{
    display:'flex',
    justifyContent:"center",
    alignItems:"center",
    fontSize:"1rem",
    fontWeight:"bold", 
    width:'50px',
    whiteSpace:'nowrap'   
  },
  discrepancy:{
    fontSize:"1rem",
    fontWeight:"bold",    
    textAlign:'center'
  }
}));

export function YourPercent({percent}) {
  const classes = useStyles();
  const percentColors = ['#DDDDDD','#785942','#785942','#257FB8','#257FB8','#204F6C','#204F6C','#204F6C','#B87312','#B87312','#B87312','#B87312'];  
  const xPercentStep = percent/10;
  const colorIndex =Math.trunc(xPercentStep)+1;
  return (
    <div className={classes.container}>
        <div className={classes.percentText} style={{color:percentColors[colorIndex]}}>
          <span>{percent>0?'+ '+percent:percent}%&nbsp;</span>
        </div>
        {[0,1,2,3,4,5,6,7,8,9].map((step)=>(
          <div className={classes.percentStep} style={{backgroundColor:(xPercentStep>+step?percentColors[colorIndex]:percentColors[0])}}>          
          </div>
        ))}
    </div>
  );
}
export function Discrepancy({percent}) {
  const classes = useStyles(); 
  return (          
        <div className={classes.discrepancy}>
          {percent}%
        </div>   
  );
}
