import React, { useState, useEffect} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { PriceComponent,PercentComponent } from "../../utility/utility";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";

import HistoricalPricingAllDialog from "./HistoricalPricingAllDialog";

import LineGraph from "../Charts/LineGraph";
import BannersLineGraph from "../SRPPricingTool/BannersLineGraph";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BarChartIcon from '@material-ui/icons/BarChart';
// import RequestPriceUp2DateButton from "../Common/RequestPriceUp2DateButton"
// import TimeAgo from "react-timeago";
// import classes from "*.module.css";
import { useSelector } from "react-redux";

import ExportButtons  from "../Common/ExportButtons";
const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    // maxHeight: "calc(100vh - 128px)",
    maxHeight: "calc(100vh - 232px)",
    boxShadow: "none",
    overflow: "auto"
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
  lineGraphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  content:{
    // overflowX: "hidden",
    // overflowY: "auto"
  },
  trHeader: {
    height: "64px",
  },
  thHeader: {
    backgroundColor: "#DDD",
    fontSize: "1rem",
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
  currentSize: {
    whiteSpace: "nowrap",
  },
  currentUpdatedAt: {
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    whiteSpace: "nowrap",
  },
  storeName: {
    // fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  storeAddress: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: "0.875rem",
    whiteSpace: "nowrap",
  },
}));
const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(246, 246, 246,0.3)',
    // padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


export default function RegularPrice({
  dataSource,
  productSelected,
  bannerDataLineGraph,
  companyColours
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [liveProductInputData, setLiveProductInputData] = useState({});
  const [dialogTitle, setDialogTitle] = useState("Historical Pricing");
  const [isCurrent, setIsCurrent] = useState(true);

  const state = useSelector((state) => state);
  const citySelected = state.city.citySelected; 
  const user = state.session.user;

  const settingColumnsArray = state.setting.columns.single_product_report;
  const bannersSelected = state.store.bannersSelected
  const byBanner = state.store.byBanner
  const [expanded, setExpanded] = useState('panel1');
  const [chartHeight, setChartHeight] = useState(0);
  const [tableContainerMaxHeight, setTableContainerMaxHeight] = useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // const storesSelected = state.store.storesSelected;
    // console.log(settingColumnsArray);
  const settingColumnsObject = settingColumnsArray.reduce(
    (acc, curr) => ((acc[curr.code] = curr), acc),
    {}
  );
  const countSelectedColumn = settingColumnsArray.filter(
    (obj) => obj.selected
  ).length;
  const printSize = (size) => {
    return size.trim().length > 0 ? size : "---";
  };
  const handleCurrentPriceOpen = (liveProductInput) => {
        
    setLiveProductInputData(liveProductInput);
    setDialogTitle(`Price History - ${liveProductInput.companyName}`);
    setIsCurrent(true)    
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCurrentRegularOpen = (liveProductInput) => {
    setLiveProductInputData(liveProductInput);
    setDialogTitle(`Reported Regular Price History - ${liveProductInput.companyName}`);
    setIsCurrent(false)
    setOpen(true);
  };
  useEffect(() => {
    let countCompany = dataSource.length-7?(dataSource.length-7):0 
    let tmpChartHeight =  countCompany*25+340
    let tmpTableContainerMaxHeight = byBanner?`calc(100vh - ${tmpChartHeight+232}px)`:"calc(100vh - 232px)"
    if(byBanner&&(!expanded)){
      tmpTableContainerMaxHeight= "calc(100vh - 288px)"
    }
    setChartHeight(tmpChartHeight)
    setTableContainerMaxHeight(tmpTableContainerMaxHeight)
  },[dataSource,byBanner,expanded])
  
  const headersExport=[
    { label: "Company", key: "company" },
    { label: "Store", key: "store" },
    { label: "Address", key: "store_address" },
    { label: "Product", key: "productName" },
    { label: "Size", key: "currentSize" },
    { label: "Standard Size", key: "currentStandardSize" },
    { label: "Price $/ea", key: "currentPrice" },
    { label: "Min $/ea", key: "current_min" },
    { label: "Max $/ea", key: "current_max" },
    { label: "% Change $/ea", key: "percent_changed" },
    { label: "Mean $/ea", key: "mean_ea" },
    { label: "Standard Deviation /ea", key: "std" },
    { label: "Most common $/ea", key: "current_mode" },
    { label: "Calculated Reg $/ea", key: "current_max" },
    { label: "Reported Reg $/ea", key: "reported_reg_ea" },
    { label: "Updated at", key: "currentUpdatedAt" },
  ];
  const productName = productSelected.name||'';
  const filename = "single-report-" + productName.replace(/[^a-z0-9]/gi, '_').toLowerCase()+".csv"
  const configExport={
    headers:headersExport,
    data:dataSource,
    filename:filename,
    target:"_blank",
  }
  //calculate height table
 
  return (
    <>
      <HistoricalPricingAllDialog
        productSelected={productSelected}
        liveProductInputData={liveProductInputData}
        title={dialogTitle}
        open={open}
        handleClose={handleClose}
        isCurrent={isCurrent}
      />
      {byBanner&&bannerDataLineGraph.labels.length>0&&(
      <Accordion className={classes.AccordionClass}  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="price-trend-for-timeframe"
          id="price-trend-for-timeframe"
        >
          <BarChartIcon/><Typography variant="inherit" style={{fontSize:'1rem',fontWeight:'500'}}>Price Trend for Timeframe</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BannersLineGraph bannerDataLineGraph={bannerDataLineGraph} companyColours={companyColours}/>
        </AccordionDetails>
      </Accordion>    
      )}
      <TableContainer component={Paper} 
      
      style={{maxHeight:`${tableContainerMaxHeight}`,boxShadow: "none",overflow: "auto" }}
      square>
        <Table stickyHeader aria-label="regular price report">
          <TableHead>
            <TableRow className={classes.trHeader}>
              <TableCell align="left" className={classes.thHeader}></TableCell>
              {settingColumnsArray
                .filter((r) => r.selected)
                .map((row, index) => {
                  if(!(byBanner&&row.code=='spr.store')){
                    return (
                      <TableCell
                        align="center"
                        key={`column_${index}`}
                        className={classes.thHeader}
                        width={row.width}
                      >
                        <Typography variant="inherit" title={row.title}>{row.name}</Typography>
                      </TableCell>
                    );
                  }else{
                    return ('')
                  }                  
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource &&
              dataSource.map((row, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell align="center">
                      <Typography variant="inherit" noWrap>
                        {user&&user.user_type_id===1&&row.extLink && (
                          <a href={row.extLink} target="_blank">
                            <IconButton
                              color="primary"
                              aria-label="product on shop"
                              component="span"
                            >
                              <LaunchIcon />
                            </IconButton>
                          </a>
                        )}
                        {/* <RequestPriceUp2DateButton
                            page="Single product pricing"
                            product={productSelected.name + " - id:"+productSelected.id}
                            city={citySelected.name}
                            store={row.company+' - '+row.store}
                            price={row.currentPrice}
                            collected_date={row.currentUpdatedAt}/> */}
                      </Typography>
                    </TableCell>
                    {settingColumnsObject["spr.company"].selected && (
                      <TableCell align="center">
                        {" "}
                        <img
                          className={classes.storeLogo}
                          src={row.company_logo_link}
                        />
                        {}
                      </TableCell>
                    )}
                    {(!byBanner)&&settingColumnsObject["spr.store"].selected && (
                      <TableCell align="left">
                        <div className={classes.storeName}>{row.store}</div>
                        <div className={classes.storeAddress}>
                          {row.store_address}
                        </div>
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.pricing_trend"].selected && (
                      <TableCell align="center">
                        <div className={classes.lineGraphContainer}>
                          <LineGraph
                            key={index}
                            storeProductPrices={row.spps}
                          />
                        </div>
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.current_ea"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          className={classes.hoverClickPopUp}
                          value={row.currentPrice}
                          style={{ color: "#204F6C" }}
                          onClick={() => handleCurrentPriceOpen(row.liveProductInput)}
                        />
                        <div className={classes.currentSize}>
                          <b>{printSize(row.currentSize)}</b>
                        </div>
                        <div className={classes.currentUpdatedAt}>
                          {row.currentUpdatedAt}
                        </div>
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.min_ea"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          value={row.current_min}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                   
                    {settingColumnsObject["spr.max_ea"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          value={row.current_max}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.percent_changed"].selected && (
                      <TableCell align="center">
                        <PercentComponent
                          value={row.percent_changed}
                          notdot4zero="true"
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.percent_changed_100g"].selected && (
                      <TableCell align="center">
                        <PercentComponent
                          value={row.percent_changed_100g}
                          notdot4zero="true"
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.mean_ea"].selected && (
                      <TableCell align="center">
                        <Typography variant="inherit">{row.mean_ea&&`${row.mean_ea}`}</Typography>
                      </TableCell>
                    )}                   
                   
                    {settingColumnsObject["spr.std"].selected && (
                      <TableCell align="center">
                        <Typography variant="inherit">{row.std_price_ea?`${row.std_price_ea.toFixed(2)}`:'---'}</Typography>
                      </TableCell>
                    )}                   
                   
                    {settingColumnsObject["spr.most_common_ea"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          value={row.current_mode}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.calculated_reg_ea"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          // value={row.estimate_regular_price}
                          value={row.current_max}
                          style={{ color: "#000" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.reported_reg_ea"].selected && (
                      <TableCell align="center">
                        {row.indexPrevReg >= 0 && (
                          <>
                            <PriceComponent
                              className={classes.hoverClickPopUp}
                              value={row.spps[row.indexPrevReg].regular_price}
                              style={{ color: "#00b04f" }}
                              onClick={() =>
                                handleCurrentRegularOpen(row.liveProductInput)
                              }
                            />                            
                          </>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {dataSource.length==0 && (
              <>
                <TableRow>
                  <TableCell align="center" colSpan={countSelectedColumn+2} style={{color:'#a39d9d'}} >
                    <br/>
                    <br/>
                    <br/>
                    <b>No data is currently available for this date range and region.</b>
                    <br/>
                    <br/>                    
                    Please try expanding the date range or changing the region.
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </TableCell>
                </TableRow>
              </>
            )}  
          </TableBody>
          <TableFooter>
            
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell colSpan={countSelectedColumn+1} className={classes.tableFooter}>{dataSource.length>0 &&<ExportButtons {...configExport}/>} </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell colSpan={countSelectedColumn + 1}>&nbsp;</TableCell>
            </TableRow> */}
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
