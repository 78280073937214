import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { Menu } from "../components/Menu";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../bin/env.config.js";
import Loading from "../components/Loading/Loading.js";
import ProductImage from "../components/Common/ProductImage.js";
import {
  selectProduct
} from "../store/actions/productAction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  tableContainer: {
    // padding: "16px",
    maxHeight: "calc(100vh - 128px)",
    boxShadow: "none",
  },
  tableRow:{
    // cursor: "pointer",
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
    textTransform: 'capitalize',
    cursor: "pointer",    
  },
  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },  
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingRow:{
    backgroundColor: '#ddd',
    animationDuration: '2s',
    highlightColor: '#a9b7c1',
    height:'70px',
    width:'100%',
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingPicture:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    height:'70px',
    width:'70px',
    marginLeft:'auto',
    marginRight:'auto'
  }
}));


export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.noWrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}

export default function ProductCoverage() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state);
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date; 
  const storesSelected = state.store.storesSelected
  const bannersSelected = state.store.bannersSelected
  const byBanner = state.store.byBanner

  const [limit, setLimit] = useState(50);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("cnt_banners");
  const [q, setQ] = useState("");
  
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const fetchDataSource = async () => {  
      setIsLoading(true);    
      const postData = {     
        storeIDs: storesSelected.map((store) => store.id),
        bannerIDs: bannersSelected,
        from_date: fromDate,
        to_date: toDate,
        limit: limit,
        skip: skip,
        order: order,
        orderBy: orderBy
      };
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/report/product-coverage`;
      const result = await axios.post(url, postData,
                                        {
                                          headers: {
                                            Authorization: state.session.token,
                                          },
                                        });
        // console.log(result.data.data)
        
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    // console.log(postData);
    fetchDataSource();
   
  }, [fromDate, toDate, storesSelected, byBanner, limit, skip, orderBy, order]);  
  
  const handleSort=(column)=>{
      if(column===orderBy) {
        setOrder(order==='asc'?'desc':'asc')
      }else{
        setOrderBy(column)
      }
  }
  const handleRowClick =(row)=>{
    let selectedProduct= state.product.products.find(x =>x.id==row.product_matching_group_id)
    // console.log(selectedProduct)
    dispatch(selectProduct(selectedProduct))
    history.push(`/single-report-electrical/`)    
  }
  
  return (
    <div className={classes.root}>
      <Menu title={"Product Coverage for "} 
          // weekSelectedButtonEnable 
          formDateToDateSelectedButtonEnable
          selectedCityEnable
          selectedStoreEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {/* <ToolbarPanel doFilter={setTags}/> */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                  square
                >
                  <Table stickyHeader aria-label="product coverage">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left"  className={classes.thHeader}></TableCell>
                        <TableCell align="left"  className={classes.thHeader}>                         
                          <SortLabel onClick={() => handleSort("fullname")}>
                            Product
                          </SortLabel>
                        </TableCell>
                        <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel onClick={() => handleSort("cnt_banners")}>
                            #Banners
                          </SortLabel>
                        </TableCell>     
                        <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel onClick={() => handleSort("cnt_stores")}>
                            #Stores
                          </SortLabel>
                        </TableCell>   
                      </TableRow>
                      </TableHead>
                    <TableBody>
                    {isLoading ? (
                      <TableRow className={classes.tableRow}>
                          <TableCell colSpan={4} >
                            <Loading />
                          </TableCell>                                           
                      </TableRow>
                    ) : (
                        <>
                        {dataSource &&
                          dataSource.map((row, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell align="center"  >
                                    <ProductImage product_matching_group_id={row.product_matching_group_id} title={row.fullname} width={'50px'} height={'50px'} company_logo_width={'20px'}/>
                                </TableCell>
                                <TableCell align="left">                         
                                    <span style={{cursor:"pointer"}} onClick={()=>handleRowClick(row)}>{row.fullname}</span>
                                </TableCell>
                                <TableCell align="center">
                                    {row.cnt_banners}
                                </TableCell>     
                                <TableCell align="center">
                                    {row.cnt_stores}
                                </TableCell>   
                              </TableRow> 
                            )})}                       
                        </>
                    )}
                    </TableBody>
                    <TableFooter>
                    
                    </TableFooter>
                  </Table>
                </TableContainer>              
            </Grid>
          </Grid>
        </Container>
        
      </main>
 
    </div>
  );
}
