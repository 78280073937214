import {GET_WEEKS, SELECT_WEEK, SELECT_FROM_DATE,SELECT_TO_DATE} from '../types'
import { format, subWeeks } from 'date-fns'
import { convertToViewDate } from "../../utility/utility.js";
export const fetchWeeks =  () => {
    return (dispatch) => {
      var curr = new Date; // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      var firstday = convertToViewDate(curr.setDate(first));
      // console.log('fetchWeeks begin')
      let temp = []
      for (let i=0; i<24; i++){
        let result = subWeeks(curr, i)
        // console.log(result);
        first = format(result, 'yyyy-MM-dd');
        firstday =  convertToViewDate(first); 
        temp.push({first:first, firstday:firstday})
      } 
      dispatch( {
        type: GET_WEEKS,
        weeks: temp,        
      })
      dispatch( {
        type: SELECT_WEEK,
        weekSelected: temp[0],        
      })
      
      let toDate = new Date()
      let fromDate = new Date()
      fromDate = fromDate.setDate(fromDate.getDate() - 180);
      dispatch( {
        type: SELECT_FROM_DATE,
        fromDateSelected: {
          date: format(fromDate, 'yyyy-MM-dd'),
          viewDate: convertToViewDate(fromDate) 
        },        
      })
      dispatch( {
        type: SELECT_TO_DATE,
        toDateSelected: {
          date: format(toDate, 'yyyy-MM-dd'),
          viewDate: convertToViewDate(toDate) 
        },        
      })
    };
};

export const selectWeek = (week) => {
   return (dispatch)=> dispatch( {
        type: SELECT_WEEK,
        weekSelected: week
    })
}

export const selectFromDate = (fromDate) => {
  return (dispatch)=> dispatch( {
       type: SELECT_FROM_DATE,
       fromDateSelected: fromDate
   })
}

export const selectToDate = (toDate) => {
  return (dispatch)=> dispatch( {
       type: SELECT_TO_DATE,
       toDateSelected: toDate
   })
}