import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowReportData
  } from "../../store/actions/myProductAction";
 
  import {      
    FormControlLabel,
    Checkbox,
    Typography
  } from "@material-ui/core";
  import  Filter from "./Filter";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    justifyContent:"space-between",
    paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: 8,
  },  
  title: {
    flexGrow: 1,
    display: "flex",
    justifyContent:"flex-start",
    alignItems:"center",
    paddingLeft: 16
  },
}));

export default function ToolbarPanel({doFilter}) {
  const classes = useStyles();
  const showReportData = useSelector(state => state.myProduct.showReportData);
  const dispatch = useDispatch()
  const handleShowReportData = ()=>{
    dispatch(setShowReportData(!showReportData))
  }
  return (  
    <Toolbar className={classes.toolbar}>
      {/* <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
       <span></span>
      </Typography> */}
        <FormControlLabel
          control={
            <Filter doFilter={doFilter}/>        
          }
          labelPlacement="start"
          label="Filter: "
        />
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={showReportData}
              onChange={handleShowReportData}
              name="Show Price Data"
              color="secondary"
            />
          }
          label="Show Price Data"
        /> */}
    </Toolbar>    
   
  );
}
