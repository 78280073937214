import React, { useState,useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {  
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel ,
  Typography
} from "@material-ui/core";

import axios from "axios";
import envConfig from "../../bin/env.config";
import Loading from "../Loading/Loading";
import { PriceComponent,randomNumber } from "../../utility/utility";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";
import { format,addDays, parseISO } from 'date-fns'
import { useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
  tableRow: {    
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
   
  },
  rowExpand:{
    '& > *': {
      borderTop:"3px solid green"
    },   
  },
  rowCollapse:{
    '& > *': {
      borderBottom:"3px solid green"
    },
    backgroundColor: "rgba(0, 0, 0, 0.04)",      
  },
  showText:{
    textTransform:'capitalize '
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  lineGraphContainer:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px',
    fontSize: "1rem", 
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
}));
export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}>
                {props.nowrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}
export function ProductImages({product}){
  const classes = useStyles();
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  return (
    <img className={classes.productImg} src={product.image_url} onError={addDefaultSrc}/>      
    )
}
export default function RowDetailExpand({product}) {
  const classes = useStyles();  
  const [isLoading, setIsLoading] = useState(true);

  const state = useSelector(state => state);  
  const fromDate = state.week.weekSelected.first;
  const toDate = format(addDays(parseISO(state.week.weekSelected.first),6),'yyyy-MM-dd');  
  const citySelected = state.city.citySelected
  const cart = state.cart.cart  
  const postData = {
    from_date: fromDate,
    to_date: toDate,
    storeID: product.store_id,
    productMatchingGroupIds: cart.map((p) => p.id),
    province: citySelected.province,
  };

  const [dataSource, setDataSource] = useState([]);
  
  useEffect(() => {
    const fetchDataSource = async () => {      
      // console.log('here'+searchText);
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/cart-comparison-detail`;
      const result = await axios.post(url, postData,
                                      {
                                        headers: {
                                          Authorization: state.session.token,
                                        },
                                      });
                                      
      let tempDataSource = result.data.data;
      citySelected.province&&setDataSource(tempDataSource);
      setIsLoading(false);
    };
    
    (dataSource.length===0)&&fetchDataSource();
  }, []);
  return (
    <>
    {isLoading ? (
      <Loading />
    ) : (
      <TableContainer className={classes.container} square>
      <Table>
        <TableHead>
            <TableRow  className={classes.trHeader}>
              <TableCell align="left"  className={classes.thHeader} width="150px">
              </TableCell>
              <TableCell align="left"  className={classes.thHeader} width="100px">
                <SortLabel hideSortIcon>Brand</SortLabel>
              </TableCell>
              <TableCell align="left"  className={classes.thHeader}>
                <SortLabel hideSortIcon>Name</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader} width="120px">
                <SortLabel hideSortIcon>Size</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader} width="120px">
                <SortLabel hideSortIcon>Price</SortLabel>
              </TableCell>             
              {/* <TableCell align="center"  className={classes.thHeader} width="120px">
                  <SortLabel hideSortIcon>View on store</SortLabel>     
               </TableCell> */}
            </TableRow>
          </TableHead>
        <TableBody>
          {
            dataSource &&
              dataSource.map((row, index) => {
                return (
                  <TableRow hover key={index} className={classes.tableRow}>
                    <TableCell align="left">
                      <ProductImages product={row}/>
                    </TableCell>
                    <TableCell align="left" className={classes.showText}>{row.brand}</TableCell>
                    <TableCell align="left" className={classes.showText}>{row.name}</TableCell>
                    <TableCell align="center">{row.size}</TableCell>
                    <TableCell align="center"><PriceComponent value={row.current_price_ea} /></TableCell>
                    {/* <TableCell align="center">
                      <Typography variant="inherit" noWrap>
                    
                        <IconButton
                          color="primary"
                          aria-label="product on shop"
                          component="span"
                          onClick={(event) => {
                            event.preventDefault();
                            window.open(
                              row.external_link ? row.external_link : "http://bettercart.ca"
                            );
                          }}
                        >
                          <LaunchIcon />
                        </IconButton>
                      </Typography>
                    </TableCell> */}
                    
                  </TableRow>
                );
              })}
        </TableBody>                
      </Table>
    </TableContainer>
    )}  
    </>
  );
}

