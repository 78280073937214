import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import BrandingWatermarkIcon from "@material-ui/icons/BrandingWatermark";
import CategoryIcon from '@material-ui/icons/Category';
import AssessmentIcon from '@material-ui/icons/Assessment';
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SecurityIcon from "@material-ui/icons/Security";
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import TransformIcon from '@material-ui/icons/Transform';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CompareIcon from '@material-ui/icons/Compare';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';

import { MemoizedSelectProvince } from "./SelectProvince";
import { MemoizedSelectCity } from "./SelectCity";
import { SelectStores } from "./SelectStores";
import { SelectByBannerButton } from "./SelectByBannerButton";
// import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import  UserMenu  from "./UserMenu";
import { useSelector } from "react-redux";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  logoBetterCart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    backgroundColor: "#296689",
    height: "128px",
  },
  filterBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    ...theme.mixins.toolbar,
    backgroundColor: "#255874",
  },
  
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',               
    textOverflow: 'ellipsis'   
  },
  drawerPaperSpacer:{
    position: "relative",    
    width: drawerWidth,
    backgroundColor: "#204F6C",
    color: "#fff",
  },
  drawerPaper: {
    position: "fixed",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#204F6C",
    // backgroundColor:"rgba(0,0,0,85)",
    color: "#fff",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  drawerFooter: {
    // borderTop: "2px solid green",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "18px",
    //paddingBottom: "px",
  },
  drawerFooterContainer: {
    // height: "300px",
    width: drawerWidth,
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,

  listItemIcon: {
    minWidth: "36px",
    // fontSize:'0.875rem'
    color: "#fff",
  },
  listItemText: {
    // fontSize:'0.875rem'
  },
  listItemSelect: {
    // fontSize:'0.875rem'
  },
  divider:{
    // height:'2px',
    backgroundColor: 'rgba(255, 255, 255, 0.70)'
  }
}));

export function MenuItem({menu}){
  const history = useHistory();
  const classes = useStyles();
  const goToURL = (route) => {
    history.push(route);
  };
  const menuList = {
    A000	:'A000'===menu,
    ADMIN	:'ADMIN'===menu,
    R001	:'R001'===menu,
    R002	:'R002'===menu,
    R003	:'R003'===menu,
    R004	:'R004'===menu,
    R005	:'R005'===menu,
    R006	:'R006'===menu,
    R007	:'R007'===menu,
    R008	:'R008'===menu,
    R009	:'R009'===menu,
    R010	:'R010'===menu,
    R011	:'R011'===menu,
    R012	:'R012'===menu,
    R013	:'R013'===menu,
    R014	:'R014'===menu,
    R015	:'R015'===menu,
    R016	:'R016'===menu,
    R017	:'R017'===menu,
    R019	:'R019'===menu,
    R018	:'R018'===menu,
    R020	:'R020'===menu,
    R021	:'R021'===menu,
    R022	:'R022'===menu,
    R023	:'R023'===menu,
    R024	:'R024'===menu,
    R025	:'R025'===menu,
    R026	:'R026'===menu,
    R027	:'R027'===menu,
    R028	:'R028'===menu,
  }
  return(
    <>
    {/* {menuList.A000&&
      <ListItem button>
          <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
          </ListItemIcon>
          <ListItemText
              className={classes.listItemText}
              primary="Pricing Dashboard"
          />
      </ListItem>
    } */}
    {/* {(userMenus.R001) && (
            <ListItem button onClick={() => goToURL("/new-products-overview")}>
              <ListItemIcon className={classes.listItemIcon}>
                <AddCircleIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="New Products"
              />
            </ListItem>
          )}
          {(userMenus.R003) && (
            <ListItem button onClick={() => goToURL("/discontinued-products")}>
              <ListItemIcon className={classes.listItemIcon}>
                <RemoveCircleIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Delisted Products "
              />
            </ListItem>
      )} */}
    
    {(menuList.R004) && (
      <ListItem button onClick={() => goToURL("/biggest-drop")}>
        <ListItemIcon className={classes.listItemIcon}>
          <TrendingDownIcon />
        </ListItemIcon>
        <ListItemText primary="Biggest Price Drops" />
      </ListItem>
    )}
    {(menuList.R006) && (
      <ListItem button onClick={() => goToURL("/biggest-increase")}>
        <ListItemIcon className={classes.listItemIcon}>
          <TrendingUpIcon />
        </ListItemIcon>
        <ListItemText primary="Biggest Price Hikes" />
      </ListItem>
    )}
    {(menuList.R007) && (
      <ListItem button onClick={() => goToURL("/battlegrounds")}>
        <ListItemIcon className={classes.listItemIcon}>
          <SecurityIcon />
        </ListItemIcon>
        <ListItemText primary="Battlegrounds" />
      </ListItem>
    )}
    {menuList.R008 && 
      <ListItem button onClick={() => goToURL("/single-report")}>
        <ListItemIcon className={classes.listItemIcon}>
          <TrackChangesIcon />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary="Exact Product Matches"
        />
      </ListItem>
    }
    {(menuList.R009) && (
      <ListItem button onClick={() => goToURL("/cart-comparison")}>
        <ListItemIcon className={classes.listItemIcon}>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Cart Comparisons" />
      </ListItem>
    )}
    {/* {userMenus.R010) && (
      <ListItem button onClick={() => goToURL("/srp")}>
        <ListItemIcon className={classes.listItemIcon}>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary="Regular Pricing Tool"
        />
      </ListItem>
    )} */}
     {/* {(userMenus.R011)&&<ListItem button>
            <ListItemIcon className={classes.listItemIcon}>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary="# of Sale Products"
            />
          </ListItem>
          }          
          {(userMenus.R012)&&<ListItem button>
            <ListItemIcon className={classes.listItemIcon}>
              <LocalAtmIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary="Flyer Sales"
            />
          </ListItem>
      } */}
    {(menuList.R013) && (
      <ListItem button onClick={() => goToURL("/your-reports")}>
        <ListItemIcon className={classes.listItemIcon}>
          <AssessmentIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
    )}
    {(menuList.R014) && (
      <ListItem button onClick={() => goToURL("/my-products")}>
        <ListItemIcon className={classes.listItemIcon}>
          <CompareIcon />
        </ListItemIcon>
        <ListItemText primary="Similar Product Matches" />
      </ListItem>
    )}    
    {(menuList.R015) && (
      <ListItem button onClick={() => goToURL("/brand-trends")}>
        <ListItemIcon className={classes.listItemIcon}>
          <BrandingWatermarkIcon />
        </ListItemIcon>
        <ListItemText primary="Brand Trends" />
      </ListItem>
    )}
    {(menuList.R016) && (
      <ListItem button onClick={() => goToURL("/category-trends")}>
        <ListItemIcon className={classes.listItemIcon}>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Segment Trends" />
      </ListItem>
    )}
    {(menuList.R018) && (
      <ListItem button onClick={() => goToURL("/sasktel-plans")}>
        <ListItemIcon className={classes.listItemIcon}>
          <AssessmentIcon />
        </ListItemIcon>
        <ListItemText primary="Plans" />
      </ListItem>
    )}
    {(menuList.R019) && (
      <ListItem button onClick={() => goToURL("/sasktel-devices")}>
        <ListItemIcon className={classes.listItemIcon}>
          <PhoneAndroidIcon />
        </ListItemIcon>
        <ListItemText primary="Devices" />
      </ListItem>
    )}
    {(menuList.R020) && (
      // <ListItem button onClick={() => goToURL("/sasktel-accessories")}>
      <ListItem button >
        <ListItemIcon className={classes.listItemIcon}>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Accessories" />
      </ListItem>
    )}
    {(menuList.R021) && (
      // <ListItem button onClick={() => goToURL("/sasktel-trade-ins")}>
      <ListItem button >
        <ListItemIcon className={classes.listItemIcon}>
          <TransformIcon />
        </ListItemIcon>
        <ListItemText primary="Trade-ins" />
      </ListItem>
    )}
    {menuList.R022 && 
      <ListItem button onClick={() => goToURL("/promo-vs-regular")}>
        <ListItemIcon className={classes.listItemIcon}>
          <AttachMoneyIcon />
          
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary="Promotion vs Regular"
        />
      </ListItem>
    }    
    {menuList.R023 && 
      <ListItem button onClick={() => goToURL("/similar-product-pricing")}>
        <ListItemIcon className={classes.listItemIcon}>
          <CompareIcon />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary="Similar Product Pricing"
        />
      </ListItem>
    }
    {menuList.R024 && 
      <ListItem button onClick={() => goToURL("/single-report-selected-stores")}>
        <ListItemIcon className={classes.listItemIcon}>
          <TrackChangesIcon />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary="SP-Pricing Selected Stores"
        />
      </ListItem>
    }
    
    {menuList.R025 && 
      <ListItem button onClick={() => goToURL("/single-report-electrical")}>
        <ListItemIcon className={classes.listItemIcon}>
          <TrackChangesIcon />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary="Exact Product Matches"
        />
      </ListItem>
    }
    {menuList.R026 && 
      <ListItem button onClick={() => goToURL("/requested-products")}>
        <ListItemIcon className={classes.listItemIcon}>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary="Requested products"
        />
      </ListItem>
    }     
    {(menuList.R027) && (
      <ListItem button onClick={() => goToURL("/my-products-electrical")}>
        <ListItemIcon className={classes.listItemIcon}>
          <CompareIcon />
        </ListItemIcon>
        <ListItemText primary="Similar Product Matches" />
      </ListItem>
    )} 
    {(menuList.R028) && (
      <ListItem button onClick={() => goToURL("/product-coverage")}>
        <ListItemIcon className={classes.listItemIcon}>
          <FormatListNumberedRtlIcon />
        </ListItemIcon>
        <ListItemText primary="Product Coverage" />
      </ListItem>
    )} 
    </>
  )  
}
export function DrawerLeftMenu(props) {  
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const menus = useSelector((state) => state.menu.menus);
  return (
    <>
      <Drawer 
      variant="permanent"
      className={classes.drawerPaperSpacer}>

      </Drawer>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open={open}
      >
        <div className={classes.logoBetterCart}>
          <img
            src="/resources/images/BC-Analytics-Logo-stacked-white.png"
            alt="BetterCart Analytics Logo"
            width="200px"
          />
        </div>
        <div className={classes.filterBlock}>
          {props.selectedProvinceEnable && (<MemoizedSelectProvince />)}            
          {props.selectedCityEnable && (<MemoizedSelectCity />)}
          {props.selectByBannerButtonEnable && (<SelectByBannerButton />)}
          {props.selectedStoreEnable && (<SelectStores />)}
        </div>
        {/* <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div> */}
        {/* <Divider /> */}
        <List>
          {menus&&menus.map((menuItem,i)=><MenuItem key={i} menu={menuItem}/>)}
        </List>
        <Divider />
        <div className={classes.drawerFooter}>
          <div className={classes.drawerFooterContainer}>
          <List>
            <UserMenu/>
          </List>
          </div>
        </div>        
      </Drawer>
    </>
      
  );
}
export const MemoizedDrawerLeftMenu = React.memo(DrawerLeftMenu);
