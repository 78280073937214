import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import SelectColumns from "./SelectColumns";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { DatePicker } from "@material-ui/pickers";
import SelectReportType from "./SelectReportType";
import { Button } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: 24,
    display: "flex",
    flexDirection: "row",
  },
  title: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  btnRoot: {
    marginLeft: "10px",
    padding: "0px 10px",
    borderWidth: "2px",
    "&:hover": {
      borderWidth: "2px",
    },
  },
  btnLabel: {
    textTransform: "capitalize",
    fontSize: "1.25rem",
    fontWeight: "500",
    "& >.MuiButton-iconSizeMedium > *:first-child ": {
      fontSize: "2rem",
    },
  },
  monthPickerInput: {
    marginLeft: "10px",
    marginRight: "10px",
    width: "120px",
    //  display:"none"
    "&:hover": {
      cursor: "pointer",
    },
    "& > input": {
      textAlign: "center",
      padding: "10px 0px",
      fontSize: "1.25rem",
      fontWeight: "500",
      textTransform: "capitalize",
      color: `#204F6C`,
    },
    "& > input:hover": {
      cursor: "pointer",
    },
    "& > fieldset": {
      top: "0px",
      marginTop: "-2px",
      color: `#204F6C`,
      border: "2px solid rgba(32, 79, 108, 0.5)",
    },
    "& >.MuiOutlinedInput-notchedOutline:hover": {
      border: `1px solid #204F6C`,
      backgroundColor: `rgba(32, 79, 108, 0.04)`,
      cursor: "pointer",
    },
  },
  formGroup: {
    alignItems: "center",
  },
}));

const SubToolbar = ({
  fromDate,
  toDate,
  reportType,
  viewReport,
  isLoading,
}) => {
  const classes = useStyles();
  // var d = new Date();  d.setMonth(d.getMonth() - 8);
  const [selectedFromDate, handleFromDateChange] = useState(fromDate);
  const [selectedToDate, handleToDateChange] = useState(toDate);
  const [rType, setRType] = useState(reportType);
  const handleViewReport = () => {
    viewReport && viewReport(selectedFromDate, selectedToDate, rType);
  };
  return (
    <Toolbar className={classes.toolbar}>
      <Typography
        component="div"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        <span>Monthly Report for </span>

        <DatePicker
          InputProps={{ className: classes.monthPickerInput }}
          variant="inline"
          inputVariant="outlined"
          openTo="year"
          views={["year", "month"]}
          format="MMM yyyy"
          // label="Year and Month"
          // helperText="Start from year selection"

          value={selectedFromDate}
          onChange={handleFromDateChange}
          disabled={isLoading}
          // rightArrowButtonProps={<ExpandMoreIcon/>}
          rightArrowIcon={<ExpandMoreIcon/>}
        />
        <span>to</span>
        <DatePicker
          InputProps={{ className: classes.monthPickerInput }}
          variant="inline"
          inputVariant="outlined"
          openTo="year"
          views={["year", "month"]}
          format="MMM yyyy"
          // label="Year and Month"
          // helperText="Start from year selection"
          value={selectedToDate}
          onChange={handleToDateChange}
          disabled={isLoading}         
        />
        <span>with</span>
        <SelectReportType
          value={rType}
          onSelected={(selected) => {
            setRType(selected);
          }}
          disabled={isLoading}
        />
        <Button
          disabled={isLoading}
          variant="outlined"
          classes={{ root: classes.btnRoot, label: classes.btnLabel }}
          onClick={handleViewReport}
          // startIcon={<i class="material-icons">done</i>}
        >
          View Report
        </Button>
      </Typography>

      <FormGroup row className={classes.formGroup}>
        {/* <Select   value={reportType} onChange={handleReportTypeChange} >  */}
        <SelectColumns />
        {/* <ExportButtons {...configExport} /> */}
      </FormGroup>
    </Toolbar>
  );
};

export default SubToolbar;
