import React,{useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import {
  TableCell,
  TableRow,
  Typography,
  Link
} from "@material-ui/core";

import PlanProviderCell from "./PlanProviderCell";

export default function PlanRow({category,plans, providers}) { 
  const history = useHistory(); 
  const handleRowClick =()=>{
    history.push(`/sasktel-plan-detail/${category}`)    
  }
  // console.log(plans)
  return (
    <TableRow>
      <TableCell align="left" style={{backgroundColor:'#f1f1f1'}}>
        <Link underline="hover"component="button"  noWrap onClick={handleRowClick} >
          {category}
        </Link>
      </TableCell>
      {providers&&providers
        .map((provider, index) => {
          let planList = plans.filter(e=>e.provider === provider.id)
          let plan = planList?planList[0]:undefined
          return (
            <PlanProviderCell key={index} plan={plan} provider={provider} displayColumns={{changeDirection:true, priceToday:true}}/>
          );
      })}                       
    </TableRow>
    
  );
}
