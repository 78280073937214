import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";


import { MemoizedAddProductButton } from "../Menu/AddProductButton";
import Toolbar from "@material-ui/core/Toolbar";

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import CartExpand from "./CartExpand";
import ProductImage from "./ProductImage";
import { MemoizedSelectSaveCarts } from "../Menu/SelectSaveCarts";
import { useSelector } from "react-redux";
import {
  Typography
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: 8
  },
  title: {
    flexGrow: 1,
    display: "flex",
    justifyContent:"flex-start",
    alignItems:"center",
    paddingLeft: 16
  },
  rowExpand:{   
    borderTop:"3px solid green"
     
  },
  productImgColumn: {
    // flex: "20%",
    position: 'relative',
    padding: "0px 5px",
  },
  productImg: {
    width: "auto",
    height: "50px",
    maxWidth: "50px",
    objectFit: "cover",
    display: "block",
  },
  customizedButton: {
    position: 'absolute',
    left: '25px',
    top: '25px',
    width: '15px',
    height: '15px',
    backgroundColor: 'lightgray',
    color: 'gray',
  }
}));
export function ProductsCount(){
  const products =  useSelector(state => state.cart.cart);
  return(
    <>{products.length}</>
  )
}
export function ProductImages() {
  const classes = useStyles();
  const products =  useSelector(state => state.cart.cart);
  const addDefaultSrc = (event) => {
    event.target.src = "/resources/images/no-picture-available.png";
  };
  return (
    <>
      {products &&
        products.map((product) => {
          return (
            <ProductImage product={product}/>
          );
        })}
    </>
  );
}
export default function CartPanel() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false); 
  
  // const products = state.product.products
  const handleClick = ()=>{
    setOpen(!open)
  }
  return (
    <>
    <Toolbar className={clsx(classes.toolbar, open && classes.rowExpand)}>
      <IconButton aria-label="expand row" size="small" onClick={handleClick}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
       <span>You have <ProductsCount/> Products in your Cart:</span><ProductImages />
      </Typography>
      <MemoizedAddProductButton  />
      <MemoizedSelectSaveCarts />
    </Toolbar>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <CartExpand/>              
    </Collapse>
   </>
  );
}
