import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel ,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSort } from '@fortawesome/free-solid-svg-icons'
import RowDetail from "./RowDetail";

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "92vh",
    boxShadow: "none",
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
 
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  tableRow:{
   
  },
  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  }, 
 
}));

export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.nowrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}

// export function StoreLabel({stores,store_id}){
//   const classes = useStyles();
//   const currStore = stores.find((p) => p.id == Number(store_id)) 
//   return (
//     <div>
//        <img className={classes.storeLogo} src={currStore.company_logo_link}/>
//        <span>{currStore.name}</span>
//     </div>
         
//   )
// } 
export default function Overview(props) {
  const classes = useStyles();
  const [dataSource, setDataSource] = useState(props.dataSource);
  const [orderDirection, setOrderDirection] = useState(1);
 
  const handleSort=(column)=>{

    setOrderDirection(-orderDirection);
    let tempDataSource = [...props.dataSource]
    column =='your_percent'||column =='low_price'||column =='high_price'?
    tempDataSource.sort((a,b) => (a[column] - b[column])*orderDirection)
    :tempDataSource.sort((a,b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0)*orderDirection);
    setDataSource(tempDataSource);
  }
  
   return (
    <>
      <TableContainer component={Paper} className={classes.container} square>
        <Table stickyHeader aria-label="battleground overview">
          <TableHead>
            <TableRow  className={classes.trHeader}>
              <TableCell className={classes.thHeader}/>
              <TableCell align="left"  className={classes.thHeader}/>

              <TableCell align="left"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('brand')}>Brand</SortLabel>
              </TableCell>
              <TableCell align="left"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('name')}>Name</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                <SortLabel hideSortIcon>Size</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('low_price')} nowrap>Low $</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('high_price')} nowrap>High $</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('difference')} nowrap>% Discrepancy</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader} width="150px">                           
                  <SortLabel hideSortIcon nowrap> Your %</SortLabel>               
              </TableCell>             
            </TableRow>
          </TableHead>
          <TableBody>
            {
            dataSource &&
              dataSource.slice(0,20).map((row, index) => (<RowDetail key={index} row={row} {...props}/>))}
          </TableBody>
          
        </Table>
      </TableContainer>
    </>
  );
}
