import React, { useState, useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";

import { MemoizedMenu } from "../components/Menu";

import axios from "axios";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import { useSelector } from "react-redux";
import ExportButtons from "../components/Common/ExportButtons";
import SubToolbar from "../components/YourReports/SubToolbar.js";
import { getStandardDeviation } from "../utility/utility";
import ViewDetailDialog from "../components/YourReports/ViewDetailDialog";

// import { PostAddTwoTone } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },
  tableContainer: {
    // padding: "16px",
    maxHeight: "calc(100vh - 128px)",
    boxShadow: "none",
  },
  contentDataTable: {
    marginTop: 15,
  },
  trRow:{
    cursor: "pointer",    
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

export default function YourReports() {
  const classes = useStyles();
  const token = useSelector((state) => state.session.token);
  const productListSelected = useSelector((state) => state.report.productListSelected);
  const cities = useSelector(state => state.city.cities);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [headersExport, setHeadersExport] = useState([]);
  var d = new Date();
  d.setDate(1);
  d.setMonth(0);
  var t = new Date();
  t.setDate(1);
  t.setMonth(t.getMonth() - 2);
  const [postData, setPostData] = useState({
    fromDate: d,
    toDate: t,
    reportType: 1,
    productList: productListSelected.id
  });

  const configExport = {
    headers: headersExport,
    data: dataSource,
    filename: "monthly_report.csv",
    target: "_blank",
  };

  const settingColumnsArray = useSelector(
    (state) => state.setting.columns.month_reports
  );
  const settingColumnsObject = settingColumnsArray.reduce(
    (acc, curr) => ((acc[curr.code] = curr), acc),
    {}
  );
  const countSelectedColumn = settingColumnsArray.filter(
    (obj) => obj.selected
  ).length;
  const handleViewReport = (fDate, tDate, rType) => {
    setPostData({ fromDate: fDate, toDate: tDate, reportType: rType, productList:productListSelected.id });
  };
  useEffect(() => {setPostData({...postData, productList:productListSelected.id });},[productListSelected]);
  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);

      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/monthly-report`;
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: token,
        },
      });
      //prepare data
      let columns = result.data.data.columns;
      // console.log(columns)
      let products = result.data.data.products;
      var data = [];
      var clen = columns.length;
      var headers = [
                      { label: "Category", key: "cat_", align:"left" },
                      { label: "Product Name", key: "pro_", align:"left" },
                      { label: "Province", key: "pre_", align:"left" },
                      { label: "City", key: "cit_", align:"left" },
                      ];
      while (clen--) {
        headers.push({
          label: `${columns[clen].name} avg`,
          key: `avg_${columns[clen].id}`,
        });
        headers.push({
          label: `${columns[clen].name} std`,
          key: `std_${columns[clen].id}`,
        });
        headers.push({
          label: `${columns[clen].name} min`,
          key: `min_${columns[clen].id}`,
        });
        headers.push({
          label: `${columns[clen].name} max`,
          key: `max_${columns[clen].id}`,
        });
      }
      headers.push({
        label: `Avg over all months`,
        key: `aoa_`,
      });
      headers.push({
        label: `Standard deviation over all months`,
        key: `soa_`,
      });
      headers.push({
        label: `Range over all months`,
        key: `ran_`,
      });
      headers.push({
        label: `% Change`,
        key: `per_`,
      });

      var plen = products.length;
      
      while (plen--) {
        var citiesLen = cities.length;
        while (citiesLen--) {
          var retRow = {
            pid_: products[plen].id,
            cat_: products[plen].category,
            pro_: products[plen].name,
            pre_: cities[citiesLen].province,
            cit_: cities[citiesLen].name,
            cid: cities[citiesLen].id,
          };
          var avgTotal = 0;
          var avgArr = [];
          var minAll = 0;
          var maxAll = 0;
          clen = columns.length;
          while (clen--) {
            let cellData = result.data.data.data.find(
              ({ pid, cid, city }) =>
                pid === products[plen].id && cid === columns[clen].id && city === cities[citiesLen].name
            );
            if (cellData) {
              retRow[`avg_${columns[clen].id}`] = cellData.average;
              retRow[`std_${columns[clen].id}`] = cellData.standard_deviation;
              retRow[`min_${columns[clen].id}`] = cellData.min;
              retRow[`max_${columns[clen].id}`] = cellData.max;
               //total
              avgTotal += cellData.average;
              avgArr.push(cellData.average);
              minAll =
                cellData.min < minAll || minAll === 0 ? cellData.min : minAll;
              maxAll =
                cellData.max > maxAll || maxAll === 0 ? cellData.max : maxAll;
            }
           
          }
          retRow[`aoa_`] = Math.round((avgTotal * 100) / columns.length) / 100;
          retRow[`soa_`] = avgArr.length>1?Math.round(getStandardDeviation(avgArr) * 100) / 100:0;
          retRow[`ran_`] = `${minAll} - ${maxAll}`;

          let firstCellData = result.data.data.data.find(
            ({ pid, cid,city }) => pid === products[plen].id && cid === columns[columns.length-1].id && city === cities[citiesLen].name
          );
          let lastCellData = result.data.data.data.find(
            ({ pid, cid,city }) => pid === products[plen].id && cid === columns[0].id && city === cities[citiesLen].name
          );
          // console.log(`firstCellData:`);
          // console.log(columns);
          // console.log(columns[columns.length-1]);
          // console.log(firstCellData);
          // console.log(`lastCellData`);
          // console.log(columns[0]);
          // console.log(lastCellData);
          

          if(firstCellData&&lastCellData){
            if(firstCellData.average==0){
              retRow[`per_`] =  `No price record`
            }else{
                let percent = 
                    Math.round(
                      (( lastCellData.average-firstCellData.average) * 1000) /
                        firstCellData.average
                    ) / 10;
                retRow[`per_`] = percent>0?`+${percent}%`:`${percent}%`
                retRow[`per_x`] =  1
            }
          }else{
            retRow[`per_`] =  `No price record`
            
          }

          retRow[`aoa_`]&&retRow[`per_x`]&&data.push(retRow);
        }
      }
      setDataSource(data);
      setHeadersExport(headers);
      setColumns(
        columns.sort(function (a, b) {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        })
      );
      setIsLoading(false);
    };
    postData && fetchDataSource();
  }, [postData]);
  const dialogViewDetail = useRef(null);
  const dialogConfig = useRef(null);
  const handleOpenViewDetail = (product) => {
    dialogViewDetail.current.doOpen(product);
  };
  
  return (
    <div className={classes.root}>
      <MemoizedMenu selectProductListEnable title={""} 
      selectedCityEnable
      selectedStoreEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {/* Button export */}
          <SubToolbar
            {...postData}
            viewReport={handleViewReport}
            isLoading={isLoading}
          />

          {/* <ExportButtons {...configExport} />  */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {isLoading ? (
                <Loading />
              ) : (
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                  square
                >
                  <Table stickyHeader aria-label="monthly report">
                    <TableHead>
                      <TableRow>
                        {headersExport &&
                          headersExport.map((row, index) => {
                            //       {settingColumnsObject["spr.company"].selected && (
                            return settingColumnsObject[row.key.substring(0, 4)]
                              .selected ? (
                              <TableCell align={row.align?row.align:"center"} key={`column_${index}`}>
                                <Typography variant="inherit">
                                  {row.label}
                                </Typography>
                              </TableCell>
                            ) : (
                              ""
                            );
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataSource &&
                        dataSource.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              key={index}
                              onClick={() => handleOpenViewDetail(row)} className={classes.trRow}
                            >
                              {settingColumnsObject["pro_"].selected && (
                                <TableCell
                                  align="left"
                                  style={{
                                    backgroundColor:
                                      settingColumnsObject["pro_"].color,
                                  }}
                                >
                                  <Typography variant="inherit" noWrap>
                                    {row.pro_}
                                  </Typography>
                                </TableCell>
                              )}
                              <TableCell
                                  align="left"
                                  style={{
                                    backgroundColor:
                                      settingColumnsObject["pre_"].color,
                                  }}
                                >
                                  <Typography variant="inherit" noWrap>
                                    {row.pre_}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    backgroundColor:
                                      settingColumnsObject["cit_"].color,
                                  }}
                                >
                                  <Typography variant="inherit" noWrap>
                                    {row.cit_}
                                  </Typography>
                                </TableCell>  
                              {columns.map((col) => {
                                return (
                                  <>
                                    {settingColumnsObject["avg_"].selected && (
                                      <TableCell
                                        align="center"
                                        style={{
                                          backgroundColor:
                                            settingColumnsObject["avg_"].color,
                                        }}
                                      >
                                        <Typography variant="inherit" noWrap>
                                          {row[`avg_${col.id}`]}
                                        </Typography>
                                      </TableCell>
                                    )}
                                    {settingColumnsObject["std_"].selected && (
                                      <TableCell
                                        align="center"
                                        style={{
                                          backgroundColor:
                                            settingColumnsObject["std_"].color,
                                        }}
                                      >
                                        <Typography variant="inherit" noWrap>
                                          {row[`std_${col.id}`]}
                                        </Typography>
                                      </TableCell>
                                    )}
                                    {settingColumnsObject["min_"].selected && (
                                      <TableCell
                                        align="center"
                                        style={{
                                          backgroundColor:
                                            settingColumnsObject["min_"].color,
                                        }}
                                      >
                                        <Typography variant="inherit" noWrap>
                                          {row[`min_${col.id}`]}
                                        </Typography>
                                      </TableCell>
                                    )}
                                    {settingColumnsObject["max_"].selected && (
                                      <TableCell
                                        align="center"
                                        style={{
                                          backgroundColor:
                                            settingColumnsObject["max_"].color,
                                        }}
                                      >
                                        <Typography variant="inherit" noWrap>
                                          {row[`max_${col.id}`]}
                                        </Typography>
                                      </TableCell>
                                    )}
                                  </>
                                );
                              })}
                              {settingColumnsObject["aoa_"].selected && (
                                <TableCell
                                  align="center"
                                  style={{
                                    backgroundColor:
                                      settingColumnsObject["aoa_"].color,
                                  }}
                                >
                                  <Typography variant="inherit" noWrap>
                                    {row.aoa_}
                                  </Typography>
                                </TableCell>
                              )}
                              {settingColumnsObject["soa_"].selected && (
                                <TableCell
                                  align="center"
                                  style={{
                                    backgroundColor:
                                      settingColumnsObject["soa_"].color,
                                  }}
                                >
                                  <Typography variant="inherit" noWrap>
                                    {row.soa_}
                                  </Typography>
                                </TableCell>
                              )}
                              {settingColumnsObject["ran_"].selected && (
                                <TableCell
                                  align="center"
                                  style={{
                                    backgroundColor:
                                      settingColumnsObject["ran_"].color,
                                  }}
                                >
                                  <Typography variant="inherit" noWrap>
                                    {row.ran_}
                                  </Typography>
                                </TableCell>
                              )}
                              {settingColumnsObject["per_"].selected && (
                                <TableCell
                                  align="center"
                                  style={{
                                    backgroundColor:
                                      settingColumnsObject["per_"].color,
                                  }}
                                >
                                  <Typography variant="inherit" noWrap>
                                    {row.per_}
                                  </Typography>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow >
                        <TableCell colSpan={countSelectedColumn} className={classes.tableFooter} style={{borderBottom:'0px'}}>
                          <ExportButtons {...configExport} />
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
              <TableCell colSpan={countSelectedColumn + 1}>&nbsp;</TableCell>
            </TableRow> */}
                    </TableFooter>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
        </Container>
        <ViewDetailDialog
          ref={dialogViewDetail}
          columns={columns}
          {...postData}
        />
      
      </main>
    </div>
  );
}
