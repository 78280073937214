import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import { MemoizedSelectProduct } from "./Menu/SelectOneProductDropDowButton";
import { MemoizedSelectCustomerProduct } from "./Menu/SelectOneCustomerProductDropDowButton";
import { MemoizedSelectWeek } from "./Menu/SelectWeek";
import { MemoizedSelectFromDateToDate } from "./Menu/SelectFromDateToDate";
import { MemoizedDrawerLeftMenu } from "./Menu/DrawerLeftMenu";
import SelectCategory from "./Menu/SelectCategory";
import SelectProductList from "./YourReports/SelectProductList";
import { MemoizedSelectSaveCarts } from "./Menu/SelectSaveCarts";
import { AddMyProductButton } from "./Menu/AddMyProductButton";
import { AddBrandButton } from "./Menu/AddBrandButton";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0px 0px 0px 0px ",
    // boxShadow:
    //   "1px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 1px 0px 0px 0px rgba(0,0,0,0.12)",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth + 1}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',               
    textOverflow: 'ellipsis'   
  },
  divider:{
    // height:'2px',
    backgroundColor: 'rgba(255, 255, 255, 0.70)'
  }
}));

export function Menu(props) {
  const history = useHistory();
  const classes = useStyles();
  const { title, goBackButtonEnable } = props;
 
  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {goBackButtonEnable && (
            <IconButton
              color="inherit"
              onClick={() => history.goBack()}
              aria-label="Close"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {props.selectedCategoryEnable && (
              <SelectCategory {...props} allCategories="All Products" />
            )}
            {props.selectProductListEnable && (
              <SelectProductList />
            )}
            {title}
            
            {/* BetterCart Analytics - Powering Your Competitive Advantage! */}
            
            {props.selectedCustomerProductEnable && <MemoizedSelectCustomerProduct />}
            {props.selectedProductEnable && <MemoizedSelectProduct />}
            {props.weekSelectedButtonEnable && (
              <MemoizedSelectWeek {...props} />
            )}
            {props.selectedCategoryEnableEnd && " in "}
            {props.selectedCategoryEnableEnd && (
              <SelectCategory {...props} allCategories="All Categories" />
            )}
            {props.formDateToDateSelectedButtonEnable && (
              <MemoizedSelectFromDateToDate {...props} />
            )}
          </Typography>
          {props.savedCartButtonEnable && (
            <MemoizedSelectSaveCarts {...props} />
          )}
          {props.addProductButtonEnable && (
            <AddMyProductButton {...props} />
          )}
          {props.addBrandButtonEnable && (
            <AddBrandButton {...props} />
          )}
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>*/}
          {/* <UserIconButton/> */}
          {/* <IconButton color="inherit">
            <PersonIcon />
          </IconButton> 
          <IconButton color="inherit" onClick={logOut}>
            <ExitToAppIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <MemoizedDrawerLeftMenu {...props}/>     
    </>
  );
}
export const MemoizedMenu = React.memo(Menu);
